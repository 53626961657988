import Vue from "vue";
import { ButtonPlugin } from "@syncfusion/ej2-vue-buttons";
Vue.use(ButtonPlugin);
export default Vue.extend({
    props: {
        cssClass: {
            type: String,
            required: false
        },
        iconCss: {
            type: String,
            required: false,
            default: ""
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    }
});
