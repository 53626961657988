import customerSlotsRoleApi from "@/api/customersTimeSlots";
const state = {};
const actions = {
    getAll(context) {
        return customerSlotsRoleApi.getAll();
    },
    get(context, payload) {
        return customerSlotsRoleApi.get(payload.id);
    },
    create(context, payload) {
        return customerSlotsRoleApi.create(payload.timeSlot);
    },
    edit(context, payload) {
        return customerSlotsRoleApi.edit(payload.id, payload.timeSlot);
    }
};
const getters = {};
const mutations = {};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
