import { Role } from "@/config/role";
const employeesMenu = [
    {
        key: "employees",
        path: "",
        icon: "fas fa-user-friends",
        title: "employees.menu",
        meta: {
            isPublic: false,
            authorize: [Role.OfficeAdministrator, Role.Administrator]
        },
        children: [
            {
                path: "/employees",
                name: "employees",
                meta: {
                    isPublic: false,
                    authorize: [Role.OfficeAdministrator, Role.Administrator]
                },
                component: () => import(/* webpackChunkName: "employees/grid" */ "@/views/employees/Index.vue"),
                key: "employees.index",
                title: "employees.menu",
                icon: "fas fa-user-friends",
                routes: [
                    {
                        path: "/employees/create",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "employees/create" */ "@/views/employees/Create.vue")
                    },
                    {
                        path: "/employees/:id/edit",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "employees/edit" */ "@/views/employees/Edit.vue")
                    },
                    {
                        path: "/employees/:employeeId/work-weeks",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "employees/work-weeks/grid" */ "@/views/employees/workWeeks/Index.vue")
                    },
                    {
                        path: "/employees/:employeeId/work-weeks/create",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "employees/work-weeks/create" */ "@/views/employees/WorkHoursCreate.vue")
                    },
                    {
                        path: "/employees/:employeeId/work-weeks/:workWeekId/edit",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "employees/work-weeks/edit" */ "@/views/employees/WorkHoursEdit.vue")
                    },
                    {
                        path: "/employees/:employeeId/daily-work-availabilities",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "employees/daily-work-availabilities/grid" */ "@/views/dailyWorkAvailability/Index.vue")
                    },
                    {
                        path: "/employees/:employeeId/daily-work-availabilities/create",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "employees/daily-work-availabilities/create" */ "@/views/dailyWorkAvailability/CreateOrEdit.vue")
                    },
                    {
                        path: "/employees/:employeeId/daily-work-availabilities/edit/:id",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "employees/daily-work-availabilities/edit" */ "@/views/dailyWorkAvailability/CreateOrEdit.vue")
                    },
                    {
                        path: "/:ownerType/:ownerId/attachments",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "employees/attachments/grid" */ "@/views/attachments/Index.vue")
                    },
                    {
                        path: "/:ownerType/:ownerId/attachments/create",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "employees/attachments/create" */ "@/views/attachments/Create.vue")
                    },
                    {
                        path: "/:ownerType/:ownerId/attachments/:id/edit",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "employees/attachments/edit" */ "@/views/attachments/Edit.vue")
                    },
                    {
                        path: "/:ownerType/:ownerId/attachments/:attachmentId/logs",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "employees/attachments/logs/grid" */ "@/views/attachments/logs/Index.vue")
                    },
                ]
            },
            {
                path: "/employee-roles",
                name: "employeeRoles",
                component: () => import(/* webpackChunkName: "employee-roles/grid" */ "@/views/employeeRoles/Index.vue"),
                key: "employeeRoles.index",
                title: "employeeRoles.menu",
                icon: "fas fa-user-tag",
                meta: {
                    isPublic: false,
                    authorize: [Role.OfficeAdministrator, Role.Administrator]
                },
                routes: [
                    {
                        path: "/employee-roles/create",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "employee-roles/create" */ "@/views/employeeRoles/Create.vue")
                    },
                    {
                        path: "/employee-roles/:id/edit",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "employee-roles/edit" */ "@/views/employeeRoles/Edit.vue")
                    }
                ]
            }
        ]
    }
];
export default employeesMenu;
