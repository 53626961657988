import httpFactory, { HttpType } from "@/api/httpFactory";
export default {
    async getAll() {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("countries");
        return response.data;
    },
    async getByCode(code) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`countries/${code}`);
        return response.data;
    },
    async getRegions(countryCode) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`countries/${countryCode}/regions`);
        return response.data;
    },
    async getRegionByCode(countryCode, regionCode) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`countries/${countryCode}/regions/${regionCode}`);
        return response.data;
    }
};
