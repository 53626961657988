import Vue from "vue";
import { mapGetters } from "vuex";
export default Vue.extend({
    data() {
        return {};
    },
    computed: {
        ...mapGetters(["version"]),
        currentYear() {
            const currentDate = new Date();
            return currentDate.getFullYear();
        }
    }
});
