import Vue from "vue";
export default Vue.extend({
    data() {
        return {
            title: this.$t("account.changePassword.title"),
            currentPassword: "",
            newPassword: "",
            newPasswordConfirm: "",
            validationOptions: {
                rules: {
                    currentPassword: {
                        required: true,
                        minLength: 8,
                        maxLength: 255
                    },
                    newPassword: {
                        required: true,
                        minLength: 8,
                        maxLength: 255
                    },
                    newPasswordConfirm: {
                        required: true,
                        minLength: 8,
                        maxLength: 255,
                        match: [
                            () => {
                                return (this.newPassword == this.newPasswordConfirm);
                            },
                            this.$t("account.changePassword.passwordConfirmationNotMatch")
                        ]
                    }
                }
            }
        };
    },
    methods: {
        clearData() {
            setTimeout(() => {
                this.currentPassword = "";
                this.newPassword = "";
                this.newPasswordConfirm = "";
            }, 1000);
        },
        changePassword() {
            return this.$store.dispatch("account/changePassword", {
                currentPassword: this.currentPassword,
                newPassword: this.newPassword
            });
        },
        changePasswordSuccess() {
            this.$toast.showSuccessToast(this.$t('account.changePassword.passwordChanged'), this.$t('account.changePassword.passwordChangedMessage'));
            this.$root.$emit("changePasswordSuccess");
            this.clearData();
        },
        cancel() {
            this.$root.$emit("changePasswordCancelled");
            this.clearData();
        }
    }
});
