import Vue from "vue";
export default Vue.extend({
    props: {
        errors: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        }
    }
});
