import Vue from "vue";
import { mapGetters } from "vuex";
import AppToolbar from "@/components/Toolbar/AppToolbar.vue";
import AppNavigation from "@/components/Navigation/AppNavigation.vue";
import AppFooter from "@/components/Footer/AppFooter.vue";
import Menu from "@/config/menu/menu";
import ChangePasswordModal from "@/components/Account/ChangePasswordModal.vue";
export default Vue.extend({
    data() {
        return {};
    },
    components: {
        AppToolbar,
        AppNavigation,
        AppFooter,
        ChangePasswordModal
    },
    methods: {},
    computed: {
        menu() {
            return Menu.getTree;
        },
        ...mapGetters({ isLoggedIn: "account/isLoggedIn" })
    }
});
