import attachmentTypeApi from "@/api/attachmentTypes";
const state = {};
const actions = {
    async getAll(context, payload) {
        return await attachmentTypeApi.getAll(payload.ownerType);
    },
    async get(context, payload) {
        return await attachmentTypeApi.get(payload.id);
    },
    async edit(context, payload) {
        await attachmentTypeApi.edit(payload.id, payload.attachmentType);
    },
    async create(context, payload) {
        await attachmentTypeApi.create(payload.attachmentType);
    }
};
const getters = {};
const mutations = {};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
