import httpFactory, { HttpType } from "@/api/httpFactory";
export default {
    async getAll() {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("offices");
        response.data.forEach(office => {
            if (office.openingTime != null)
                office.openingTime = new Date(office.openingTime);
            if (office.closingTime != null)
                office.closingTime = new Date(office.closingTime);
        });
        return response.data;
    },
    async get(id) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`offices/${id}`);
        if (response.data.openingTime != null)
            response.data.openingTime = new Date(response.data.openingTime);
        if (response.data.closingTime != null)
            response.data.closingTime = new Date(response.data.closingTime);
        return response.data;
    },
    async create(request) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post("offices/create", request);
        if (response.data.openingTime != null)
            response.data.openingTime = new Date(response.data.openingTime);
        if (response.data.closingTime != null)
            response.data.closingTime = new Date(response.data.closingTime);
        return response.data;
    },
    async edit(id, request) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post(`offices/${id}/edit`, request);
        if (response.data.openingTime != null)
            response.data.openingTime = new Date(response.data.openingTime);
        if (response.data.closingTime != null)
            response.data.closingTime = new Date(response.data.closingTime);
        return response.data;
    }
};
