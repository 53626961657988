import httpFactory, { HttpType } from "@/api/httpFactory";
export default {
    async getServiceStatistics(startDate, endDate, employeeId, officeId, granularity) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("statistics/services", {
            params: {
                startDate,
                endDate,
                employeeId,
                officeId,
                granularity
            }
        });
        return response.data;
    },
    async getServiceRevenueStatistics(startDate, endDate, employeeId, officeId, granularity) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("statistics/services/revenue", {
            params: {
                startDate,
                endDate,
                employeeId,
                officeId,
                granularity
            }
        });
        return response.data;
    },
    async getServiceProfitStatistics(startDate, endDate, employeeId, officeId, granularity) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("statistics/employees/profit", {
            params: {
                startDate,
                endDate,
                employeeId,
                officeId,
                granularity
            }
        });
        return response.data;
    },
    async getServiceCostOverRevenueStatistics(startDate, endDate, employeeId, officeId, granularity) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("statistics/services/cost-revenue", {
            params: {
                startDate,
                endDate,
                employeeId,
                officeId,
                granularity
            }
        });
        return response.data;
    },
    async getServiceEmployeeCostStatistics(startDate, endDate, employeeId, officeId, granularity) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("statistics/employees/cost", {
            params: {
                startDate,
                endDate,
                employeeId,
                officeId,
                granularity
            }
        });
        return response.data;
    },
    async getServicesTrendStatistics(startDate, endDate, employeeId, officeId, granularity) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("statistics/services/trend", {
            params: {
                startDate,
                endDate,
                employeeId,
                officeId,
                granularity
            }
        });
        response.data.data.forEach(statistic => {
            statistic.date = new Date(statistic.date);
        });
        return response.data;
    },
    async getWorkedHoursStatistics(startDate, endDate, employeeId, officeId, granularity) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("statistics/employees/worked-hours", {
            params: {
                startDate,
                endDate,
                employeeId,
                officeId,
                granularity
            }
        });
        response.data.data.forEach(statistic => {
            statistic.date = new Date(statistic.date);
        });
        return response.data;
    },
    async getCustomersRetention(startDate, endDate, employeeId, officeId, granularity) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("statistics/customers/retention", {
            params: {
                startDate,
                endDate,
                employeeId,
                officeId,
                granularity
            }
        });
        response.data.data.forEach(statistic => {
            statistic.date = new Date(statistic.date);
        });
        return response.data;
    },
    async getCustomersAcquisitionChannels(startDate, endDate, granularity) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("statistics/customers/acquisition-channels", {
            params: {
                startDate,
                endDate,
                granularity
            }
        });
        response.data.data.forEach(statistic => {
            statistic.date = new Date(statistic.date);
        });
        return response.data;
    },
    async getCustomersTimeSlots(startDate, endDate, granularity) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("statistics/customers/time-slots", {
            params: {
                startDate,
                endDate,
                granularity
            }
        });
        response.data.data.forEach(statistic => {
            statistic.date = new Date(statistic.date);
        });
        return response.data;
    },
    async getHealthPathsGymConversion(startDate, endDate, employeeId, officeId, granularity) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("statistics/health-paths/gym-convertion", {
            params: {
                startDate,
                endDate,
                employeeId,
                officeId,
                granularity
            }
        });
        return response.data;
    },
    async getHealthPathsDuration(startDate, endDate, employeeId, officeId, granularity) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("statistics/health-paths/duration", {
            params: {
                startDate,
                endDate,
                employeeId,
                officeId,
                granularity
            }
        });
        return response.data;
    },
    async getHealthPathsDurationStandardDeviation(startDate, endDate, employeeId, officeId, granularity) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("statistics/health-paths/duration-standard-deviation", {
            params: {
                startDate,
                endDate,
                employeeId,
                officeId,
                granularity
            }
        });
        return response.data;
    },
    async getAppointmentsTrialsStatistics(startDate, endDate, employeeId, officeId, granularity) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("statistics/appointments/trials", {
            params: {
                startDate,
                endDate,
                employeeId,
                officeId,
                granularity
            }
        });
        response.data.data.forEach(statistic => {
            statistic.date = new Date(statistic.date);
        });
        return response.data;
    },
    async getAppointmentsTrialsOverNoNewAppointmentsStatistics(startDate, endDate, employeeId, officeId, granularity) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("statistics/appointments/trials-over-no-new-appointments", {
            params: {
                startDate,
                endDate,
                employeeId,
                officeId,
                granularity
            }
        });
        response.data.data.forEach(statistic => {
            statistic.date = new Date(statistic.date);
        });
        return response.data;
    },
    async getRangesTypes() {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("statistics/value-ranges/types");
        return response.data;
    },
    async getValueRangesById(id) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`statistics/value-ranges/${id}`);
        return response.data;
    },
    async createValueRanges(type, values) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post("statistics/value-ranges", {
            type: type,
            values: values
        });
        return response.data;
    },
    async updateValueRanges(id, type, values) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post(`statistics/value-ranges/${id}`, {
            type: type,
            values: values
        });
        return response.data;
    }
};
