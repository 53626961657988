/* eslint-disable @typescript-eslint/camelcase */
import accountApi from "@/api/account";
import jwtDecode from "jwt-decode";
const state = {
    isLoggedIn: false,
    accountInfo: null,
    refreshTokensTimeoutId: null
};
const actions = {
    async getAccountInfo(context) {
        const response = await accountApi.getAccountInfo();
        context.commit("setAccountInfo", response);
        context.commit("setLoggedIn", true);
    },
    async login(context, payload) {
        // Login
        const authenticationScheme = process.env.VUE_APP_API_AUTHENTICATION_SCHEME || "Cookies";
        const response = await accountApi.login(payload.username, payload.password, payload.keepLoggedIn, authenticationScheme);
        // Set tokens for the Bearer authentication scheme
        if (authenticationScheme === 'Bearer') {
            const storage = payload.keepLoggedIn ? localStorage : sessionStorage;
            storage.setItem("token", response.token);
            storage.setItem("refresh_token", response.refreshToken);
            await context.dispatch("scheduleRefreshTokens");
        }
        // Get account info
        await context.dispatch("getAccountInfo");
    },
    async logout(context) {
        // Logout
        await accountApi.logout();
        // Remove tokens for the Bearer authentication scheme
        if (process.env.VUE_APP_API_AUTHENTICATION_SCHEME === "Bearer") {
            await context.dispatch("clearRefreshTokenSchedule");
            sessionStorage.removeItem("token");
            localStorage.removeItem("token");
            sessionStorage.removeItem("refresh_token");
            localStorage.removeItem("refresh_token");
        }
        // Remove account info
        context.commit("setLoggedIn", false);
        context.commit("setAccountInfo", null);
    },
    async refreshToken(context) {
        if (process.env.VUE_APP_API_AUTHENTICATION_SCHEME !== "Bearer")
            return;
        // Get refresh token
        const refreshToken = sessionStorage.getItem("refresh_token") || localStorage.getItem("refresh_token");
        if (refreshToken === null)
            throw new Error("Refresh token does not exists.");
        // Refresh token
        const response = await accountApi.refreshToken(refreshToken);
        // Set tokens
        const storage = localStorage.getItem("refresh_token") !== null ? localStorage : sessionStorage;
        storage.setItem("token", response.token);
        storage.setItem("refresh_token", response.refreshToken);
    },
    changePassword(context, payload) {
        return accountApi.changePassword(payload.currentPassword, payload.newPassword);
    },
    sendResetPassword(context, payload) {
        return accountApi.sendResetPassword(payload.email);
    },
    resetPassword(context, payload) {
        return accountApi.resetPassword(payload.email, payload.token, payload.newPassword);
    },
    confirmEmail(context, payload) {
        return accountApi.confirmEmail(payload.email, payload.token);
    },
    async scheduleRefreshTokens(context) {
        if (process.env.VUE_APP_API_AUTHENTICATION_SCHEME !== "Bearer")
            return;
        await context.dispatch("clearRefreshTokenSchedule");
        const token = sessionStorage.getItem("token") || localStorage.getItem("token");
        if (token === null)
            return;
        const tokenPayload = jwtDecode(token);
        if (tokenPayload.exp == null)
            return;
        const currentTime = (new Date()).getTime();
        const delayOffset = Math.floor(Math.random() * (2 * 60 * 1000) + (1 * 60 * 1000));
        let delay = (tokenPayload.exp * 1000) - currentTime - delayOffset;
        if (delay < 0)
            delay = 0;
        const timeoutId = setTimeout(async () => {
            await context.dispatch("refreshToken");
            await context.dispatch("scheduleRefreshTokens");
        }, delay);
        context.commit("setRefreshTokensTimeoutId", timeoutId);
    },
    clearRefreshTokenSchedule(context) {
        if (process.env.VUE_APP_API_AUTHENTICATION_SCHEME !== "Bearer")
            return Promise.resolve();
        const timeoutId = context.getters.getRefreshTokensTimeoutId;
        if (timeoutId != null) {
            clearTimeout(timeoutId);
            context.commit("setRefreshTokensTimeoutId", null);
        }
        return Promise.resolve();
    }
};
const getters = {
    isLoggedIn(state) {
        return state.isLoggedIn;
    },
    getFullName(state) {
        if (state.accountInfo == null)
            return null;
        return `${state.accountInfo.firstName} ${state.accountInfo.lastName}`;
    },
    getEmail(state) {
        if (state.accountInfo == null)
            return null;
        return state.accountInfo.email;
    },
    getRole(state) {
        if (state.accountInfo == null)
            return null;
        if (state.accountInfo.roles.length === 0)
            return null;
        return state.accountInfo.roles[0];
    },
    getEmployeeId(state) {
        if (state.accountInfo == null)
            return null;
        return state.accountInfo.employeeId;
    },
    getOfficeIds(state) {
        if (state.accountInfo == null)
            return null;
        return state.accountInfo.officeIds;
    },
    hasMultipleOffices(state) {
        if (state.accountInfo == null)
            return null;
        return state.accountInfo.officeIds.length > 1;
    },
    getSingleOfficeId(state) {
        if (state.accountInfo == null)
            return null;
        if (state.accountInfo.officeIds.length !== 1)
            return null;
        return state.accountInfo.officeIds[0];
    },
    getRefreshTokensTimeoutId(state) {
        return state.refreshTokensTimeoutId;
    }
};
const mutations = {
    setLoggedIn(state, isLoggedIn) {
        state.isLoggedIn = isLoggedIn;
    },
    setAccountInfo(state, accountInfo) {
        state.accountInfo = accountInfo;
    },
    setRefreshTokensTimeoutId(state, timeoutId) {
        if (process.env.VUE_APP_API_AUTHENTICATION_SCHEME !== "Bearer")
            return;
        state.refreshTokensTimeoutId = timeoutId;
    }
};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
