import Vue from "vue";
import { DateTimePickerPlugin } from "@syncfusion/ej2-vue-calendars";
Vue.use(DateTimePickerPlugin);
export default Vue.extend({
    props: {
        id: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            required: false,
            default: ""
        },
        value: {
            type: [Date, String],
            required: false
        },
        openOnFocus: {
            type: Boolean,
            required: false,
            default: false
        },
        cssClass: {
            type: String,
            required: false,
            default: ""
        },
        allowEdit: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            dataMsgContainerId: `${this.id}-error`
        };
    },
    methods: {
        emitValue(value) {
            this.$emit("input", value);
        },
        onFocus() {
            if (this.openOnFocus)
                this.$refs[this.id].show();
        }
    }
});
