import employeeApi from "@/api/employees";
const state = {
    employees: null
};
const actions = {
    async getAll(context, payload) {
        let employees = context.getters.getEmployees;
        if (payload.load || employees == null) {
            employees = await employeeApi.getAll();
            context.commit("setEmployees", employees);
        }
        return employees;
    },
    async get(context, payload) {
        return await employeeApi.get(payload.id);
    },
    async edit(context, payload) {
        await employeeApi.edit(payload.id, payload.employee);
    },
    async create(context, payload) {
        return await employeeApi.create(payload.employee);
    },
    async isAvailable(context, payload) {
        return await employeeApi.isAvailable(payload.id, payload.officeId, payload.startDateTime, payload.endDateTime, payload.recurrenceRule, payload.recurrenceException, payload.appointmentId);
    },
    async isWorkingHours(context, payload) {
        return await employeeApi.isWorkingHours(payload.id, payload.officeId, payload.startDateTime, payload.endDateTime, payload.recurrenceRule, payload.recurrenceException);
    },
    async getAvatar(context, payload) {
        return await employeeApi.getAvatar(payload.id);
    },
    async getContract(context, payload) {
        return await employeeApi.getContract(payload.id);
    },
    async getAttachment(context, payload) {
        return await employeeApi.getAttachment(payload.id, payload.employeeId);
    },
    async createAttachment(context, payload) {
        return await employeeApi.createAttachment(payload.employeeId, payload.attachment);
    },
    async editAttachment(context, payload) {
        return await employeeApi.editAttachment(payload.id, payload.employeeId, payload.attachment);
    },
    async downloadAttachment(context, payload) {
        return await employeeApi.downloadAttachment(payload.id, payload.employeeId);
    }
};
const getters = {
    getEmployees(state) {
        return state.employees;
    }
};
const mutations = {
    setEmployees(state, employees) {
        state.employees = employees;
    }
};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
