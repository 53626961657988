import roomApi from "@/api/rooms";
const state = {
    rooms: []
};
const actions = {
    async getAll(context, payload) {
        if (payload?.load ?? true) {
            const rooms = await roomApi.getAll();
            context.commit("setRooms", rooms);
        }
        return context.getters.getRooms;
    },
    async get(context, payload) {
        return await roomApi.get(payload.id);
    },
    async edit(context, payload) {
        await roomApi.edit(payload.id, payload.room);
    },
    async create(context, payload) {
        await roomApi.create(payload.room);
    },
    async isAvailable(context, payload) {
        return await roomApi.isAvailable(payload.id, payload.startDateTime, payload.endDateTime, payload.customersNumber, payload.recurrenceRule, payload.recurrenceException, payload.appointmentId);
    }
};
const getters = {
    getRooms(state) {
        return state.rooms;
    }
};
const mutations = {
    setRooms(state, rooms) {
        state.rooms = rooms;
    }
};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
