import Vue from "vue";
import { ComboBoxPlugin } from "@syncfusion/ej2-vue-dropdowns";
Vue.use(ComboBoxPlugin);
export default Vue.extend({
    props: {
        id: {
            type: String,
            required: true
        },
        dataSource: {
            type: Array,
            required: false
        },
        enabled: {
            type: Boolean,
            required: false,
            default: true
        },
        fields: {
            type: Object,
            required: false
        },
        placeholder: {
            type: String,
            required: false,
            default: ""
        },
        value: {
            type: [String, Boolean, Number],
            required: false
        },
        cssClass: {
            type: String,
            required: false,
            default: ""
        }
    },
    data() {
        return {
            dataMsgContainerId: `${this.id}-error`
        };
    },
    methods: {
        emitValue(value) {
            this.$emit("input", value);
        }
    }
});
