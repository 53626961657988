import { Role } from "@/config/role";
const customersMenu = [
    {
        key: "customers",
        path: "",
        icon: "fas fa-users",
        title: "customers.menu",
        meta: {
            isPublic: false,
            authorize: [Role.Secretary, Role.OfficeAdministrator, Role.Administrator]
        },
        children: [
            {
                path: "/customers",
                name: "customers",
                component: () => import(/* webpackChunkName: "customers/grid" */ "@/views/customers/Index.vue"),
                key: "customers.index",
                title: "customers.menu",
                icon: "fas fa-users",
                meta: {
                    isPublic: false,
                    authorize: [Role.Secretary, Role.OfficeAdministrator, Role.Administrator]
                },
                routes: [
                    {
                        path: "/customers/:id/appointments",
                        meta: {
                            isPublic: false,
                            authorize: [Role.Secretary, Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "customers/appointments/grid" */ "@/views/customers/Appointments.vue")
                    },
                    {
                        path: "/customers/:customerId/healthPath",
                        meta: {
                            isPublic: false,
                            authorize: [Role.Secretary, Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "customers/health-paths/grid" */ "@/views/customers/healthPath/Index.vue")
                    },
                    {
                        path: "/customers/:customerId/healthPath/create",
                        meta: {
                            isPublic: false,
                            authorize: [Role.Secretary, Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "customers/health-paths/create-edit" */ "@/views/customers/healthPath/CreateOrEdit.vue")
                    },
                    {
                        path: "/customers/:customerId/healthPath/edit/:id",
                        meta: {
                            isPublic: false,
                            authorize: [Role.Secretary, Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "customers/health-paths/create-edit" */ "@/views/customers/healthPath/CreateOrEdit.vue")
                    },
                    {
                        path: "/customers/create",
                        meta: {
                            isPublic: false,
                            authorize: [Role.Secretary, Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "customers/create" */ "@/views/customers/Create.vue")
                    },
                    {
                        path: "/customers/:id/edit",
                        meta: {
                            isPublic: false,
                            authorize: [Role.Secretary, Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "customers/edit" */ "@/views/customers/Edit.vue")
                    },
                    {
                        path: "/:ownerType/:ownerId/attachments",
                        meta: {
                            isPublic: false,
                            authorize: [Role.Secretary, Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "customers/attachments/grid" */ "@/views/attachments/Index.vue")
                    },
                    {
                        path: "/:ownerType/:ownerId/attachments/create",
                        meta: {
                            isPublic: false,
                            authorize: [Role.Secretary, Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "customers/attachments/create" */ "@/views/attachments/Create.vue")
                    },
                    {
                        path: "/:ownerType/:ownerId/attachments/:id/edit",
                        meta: {
                            isPublic: false,
                            authorize: [Role.Secretary, Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "customers/attachments/edit" */ "@/views/attachments/Edit.vue")
                    },
                    {
                        path: "/:ownerType/:ownerId/attachments/:attachmentId/logs",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "customers/attachments/logs/grid" */ "@/views/attachments/logs/Index.vue")
                    },
                ]
            },
            {
                path: "/customers-time-slots",
                name: "customersTimeSlots",
                component: () => import(/* webpackChunkName: "customers-time-slots/grid" */ "@/views/customersTimeSlots/Index.vue"),
                key: "customersTimeSlots.index",
                title: "customersTimeSlots.menu",
                icon: "fas fa-clock",
                meta: {
                    isPublic: false,
                    authorize: [Role.OfficeAdministrator, Role.Administrator]
                },
                routes: [
                    {
                        path: "/customers-time-slots/create",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "customers-time-slots/create" */ "@/views/customersTimeSlots/Create.vue")
                    },
                    {
                        path: "/customers-time-slots/:id/edit",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "customers-time-slots/edit" */ "@/views/customersTimeSlots/Edit.vue")
                    }
                ]
            },
            {
                path: "/acquisition-channels",
                name: "acquisitionChannels",
                component: () => import(/* webpackChunkName: "acquisition-channels/grid" */ "@/views/acquisitionChannels/Index.vue"),
                key: "acquisitionChannels.index",
                title: "acquisitionChannels.menu",
                icon: "fas fa-magnet",
                meta: {
                    isPublic: false,
                    authorize: [Role.Secretary, Role.OfficeAdministrator, Role.Administrator]
                },
                routes: [
                    {
                        path: "/acquisition-channels/create",
                        meta: {
                            isPublic: false,
                            authorize: [Role.Secretary, Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "acquisition-channels/create" */ "@/views/acquisitionChannels/Create.vue")
                    },
                    {
                        path: "/acquisition-channels/:id/edit",
                        meta: {
                            isPublic: false,
                            authorize: [Role.Secretary, Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "acquisition-channels/edit" */ "@/views/acquisitionChannels/Edit.vue")
                    }
                ]
            }
        ]
    }
];
export default customersMenu;
