import dailyWorkAvailabilityApi from "@/api/dailyWorkAvailability";
const state = {};
const actions = {
    async create(context, payload) {
        await dailyWorkAvailabilityApi.create(payload.employeeId, payload.dailyWorkAvailabilityRequest);
    },
    async update(context, payload) {
        await dailyWorkAvailabilityApi.update(payload.employeeId, payload.id, payload.dailyWorkAvailabilityRequest);
    },
    async get(context, payload) {
        return await dailyWorkAvailabilityApi.get(payload.employeeId, payload.id);
    }
};
const getters = {};
const mutations = {};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
