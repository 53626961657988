import httpFactory, { HttpType } from "@/api/httpFactory";
export default {
    async getAccountInfo() {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get('account');
        return response.data;
    },
    async login(username, password, keepLoggedIn, authenticationScheme) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post("account/login", {
            username: username,
            password: password,
            rememberMe: keepLoggedIn,
        }, {
            headers: {
                "x-authentication-scheme": authenticationScheme
            }
        });
        if (authenticationScheme === "Bearer")
            return response.data;
    },
    async logout() {
        const http = httpFactory.getHttp(HttpType.Api);
        await http.post("account/logout");
    },
    async refreshToken(refreshToken) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post("account/refresh-token", {
            refreshToken
        });
        return response.data;
    },
    async changePassword(currentPassword, newPassword) {
        const http = httpFactory.getHttp(HttpType.Api);
        await http.post("account/change-password", {
            currentPassword,
            newPassword
        });
    },
    async sendResetPassword(email) {
        const http = httpFactory.getHttp(HttpType.Api);
        await http.post(`account/send-reset-password`, {
            email
        });
    },
    async resetPassword(email, token, newPassword) {
        const http = httpFactory.getHttp(HttpType.Api);
        await http.post("account/reset-password", {
            email,
            token,
            newPassword,
        });
    },
    async confirmEmail(email, token) {
        const http = httpFactory.getHttp(HttpType.Api);
        await http.post('account/confirm-email', {
            email,
            token
        });
    }
};
