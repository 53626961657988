import employeeWorkHourApi from "@/api/employeeWorkHours";
const state = {};
const actions = {
    async getAll(context, payload) {
        return await employeeWorkHourApi.getAll(payload.employeeId);
    },
    async get(context, payload) {
        return await employeeWorkHourApi.get(payload.employeeId, payload.workWeekId);
    },
    async create(context, payload) {
        await employeeWorkHourApi.create(payload.employeeId, payload.employeeWorkWeek);
    },
    async update(context, payload) {
        await employeeWorkHourApi.update(payload.employeeId, payload.workWeekId, payload.employeeWorkWeek);
    }
};
const getters = {};
const mutations = {};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
