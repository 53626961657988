import Vue from "vue";
import { ColorPickerPlugin } from "@syncfusion/ej2-vue-inputs";
import { DropDownButtonPlugin } from "@syncfusion/ej2-vue-splitbuttons";
Vue.use(ColorPickerPlugin);
Vue.use(DropDownButtonPlugin);
export default Vue.extend({
    props: {
        id: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: false,
            default: ""
        },
        value: {
            type: String,
            required: false,
            default: "#FFFFFFFF"
        }
    },
    data() {
        return {
            dropDownId: `${this.id}-dropdown`,
            dataMsgContainerId: `${this.id}-error`
        };
    },
    methods: {
        onChange: function () {
            this.closePopup();
        },
        dropDownOpen: function (args) {
            args.element
                .querySelector(".e-cancel")
                .addEventListener("click", this.closePopup);
        },
        beforeDropDownClose: function (args) {
            args.element
                .querySelector(".e-cancel")
                .removeEventListener("click", this.closePopup);
        },
        closePopup: function () {
            this.$refs[this.dropDownId].ej2Instances.toggle();
        },
        emitValue(value) {
            this.$emit("input", value);
        }
    },
    watch: {
        value: function (value) {
            this.$refs[this.dropDownId].ej2Instances.element.children[0].style.backgroundColor = value;
        }
    },
    mounted() {
        this.$refs[this.dropDownId].ej2Instances.element.children[0].style.backgroundColor = this.value;
    }
});
