import Vue from "vue";
import { ChipListPlugin } from '@syncfusion/ej2-vue-buttons';
Vue.use(ChipListPlugin);
export default Vue.extend({
    props: {
        cssClass: {
            type: String,
            required: false,
            default: ""
        },
        values: {
            type: Array,
            required: true
        }
    },
    methods: {
        getDataAttributes(value) {
            const dataAttributes = {};
            for (const name in value.data ?? {}) {
                const attributeName = `data-${name}`;
                const attributeValue = value.data[name];
                dataAttributes[attributeName] = attributeValue;
            }
            return dataAttributes;
        }
    }
});
