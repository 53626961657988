import Vue from "vue";
// Import input components
import AppButton from "@/components/Inputs/AppButton.vue";
import AppChip from "@/components/Inputs/AppChips.vue";
import AppCheckBox from "@/components/Inputs/AppCheckBox.vue";
import AppColorPicker from "@/components/Inputs/AppColorPicker.vue";
import AppComboBox from "@/components/Inputs/AppComboBox.vue";
import AppSwitch from "@/components/Inputs/AppSwitch.vue";
import AppDropDownList from "@/components/Inputs/AppDropDownList.vue";
import AppDropDownListYesNo from "@/components/Inputs/AppDropDownListYesNo.vue";
import AppDropDownListYesNoAll from "@/components/Inputs/AppDropDownListYesNoAll.vue";
import AppMultiSelect from "@/components/Inputs/AppMultiSelect.vue";
import AppTextBox from "@/components/Inputs/AppTextBox.vue";
import AppNumericTextBox from "@/components/Inputs/AppNumericTextBox.vue";
import AppDatePicker from "@/components/Inputs/AppDatePicker.vue";
import AppTimePicker from "@/components/Inputs/AppTimePicker.vue";
import AppDateTimePicker from "@/components/Inputs/AppDateTimePicker.vue";
// Import form components
import AppErrorsSummary from "@/components/Form/AppErrorsSummary.vue";
import AppForm from "@/components/Form/AppForm.vue";
import AppFormButtons from "@/components/Form/AppFormButtons.vue";
// Import notifications components
import AppToast from "@/components/Notifications/AppToast.vue";
Vue.component("app-button", AppButton);
Vue.component("app-chips", AppChip);
Vue.component("app-checkbox", AppCheckBox);
Vue.component("app-colorpicker", AppColorPicker);
Vue.component("app-switch", AppSwitch);
Vue.component("app-combobox", AppComboBox);
Vue.component("app-dropdownlist", AppDropDownList);
Vue.component("app-dropdownlist-yes-no", AppDropDownListYesNo);
Vue.component("app-dropdownlist-yes-no-all", AppDropDownListYesNoAll);
Vue.component("app-multiselect", AppMultiSelect);
Vue.component("app-textbox", AppTextBox);
Vue.component("app-numerictextbox", AppNumericTextBox);
Vue.component("app-datepicker", AppDatePicker);
Vue.component("app-timepicker", AppTimePicker);
Vue.component("app-datetimepicker", AppDateTimePicker);
Vue.component("app-errors-summary", AppErrorsSummary);
Vue.component("app-form", AppForm);
Vue.component("app-form-buttons", AppFormButtons);
Vue.component("app-toast", AppToast);
