import countryApi from "@/api/countries";
const state = {};
const actions = {
    getAll(context) {
        return countryApi.getAll();
    },
    getByCode(context, payload) {
        return countryApi.getByCode(payload.code);
    },
    getRegions(context, payload) {
        return countryApi.getRegions(payload.countryCode);
    },
    getRegionByCode(context, payload) {
        return countryApi.getRegionByCode(payload.countryCode, payload.regionCode);
    }
};
const getters = {};
const mutations = {};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
