import httpFactory, { HttpType } from "@/api/httpFactory";
export default {
    async getAll() {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("currencies");
        return response.data;
    },
    async getByCode(code) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`currencies/${code}`);
        return response.data;
    }
};
