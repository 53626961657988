import Vue from "vue";
import Vuex from "vuex";
import accountModule from "./account";
import attachmentModule from "./attachment";
import officeModule from "./office";
import countryModule from "./country";
import currencyModule from "./currency";
import serviceModule from "./service";
import calendarTypeModule from "./calendarType";
import customerModule from "./customer";
import customerTimeSlotModule from "./customerTimeSlot";
import acquisitionChannelModule from "./acquisitionChannels";
import employeeModule from "./employee";
import employeeRoleModule from "./employeeRole";
import employeeWorkHourModule from "./employeeWorkHour";
import roomModule from "./room";
import equipmentModule from "./equipment";
import calendarModule from "./calendar";
import statisticsModule from "./statistics";
import userModule from "./user";
import dailyWorkAvailabilityModule from "./dailyWorkAvailability";
import attachmentTypeModule from "./attachmentType";
Vue.use(Vuex);
const state = {
    appLocale: process.env.VUE_APP_I18N_LOCALE || "en",
    version: require("../../package.json").version,
    employeeAvailable: true,
    roomAvailable: true,
    equipmentAvailable: true,
    employeeErrorMessage: "",
    roomErrorMessage: "",
    equipmentErrorMessage: "",
    editorCurrentAction: "",
    menuVisible: false
};
export default new Vuex.Store({
    state,
    getters: {
        employeeAvailable(state) {
            return state.employeeAvailable;
        },
        employeeErrorMessage(state) {
            return state.employeeErrorMessage;
        },
        roomAvailable(state) {
            return state.roomAvailable;
        },
        roomErrorMessage(state) {
            return state.roomErrorMessage;
        },
        equipmentAvailable(state) {
            return state.equipmentAvailable;
        },
        equipmentErrorMessage(state) {
            return state.equipmentErrorMessage;
        },
        editorCurrentAction(state) {
            return state.editorCurrentAction;
        },
        appLocale(state) {
            return state.appLocale;
        },
        version(state) {
            return state.version;
        },
        isMenuVisible(state) {
            return state.menuVisible;
        }
    },
    mutations: {
        setEmployeeAvailable(state, payload) {
            state.employeeAvailable = payload;
        },
        setEmployeeErrorMessage(state, payload) {
            state.employeeErrorMessage = payload;
        },
        setRoomAvailable(state, payload) {
            state.roomAvailable = payload;
        },
        setRoomErrorMessage(state, payload) {
            state.roomErrorMessage = payload;
        },
        setEquipmentAvailable(state, payload) {
            state.equipmentAvailable = payload;
        },
        setEquipmentErrorMessage(state, payload) {
            state.equipmentErrorMessage = payload;
        },
        setEditorCurrentAction(state, payload) {
            state.editorCurrentAction = payload;
        },
        showMenu(state, payload) {
            state.menuVisible = payload;
        }
    },
    actions: {},
    modules: {
        account: accountModule,
        attachment: attachmentModule,
        office: officeModule,
        country: countryModule,
        currency: currencyModule,
        service: serviceModule,
        calendarType: calendarTypeModule,
        customer: customerModule,
        customerTimeSlot: customerTimeSlotModule,
        acquisitionChannel: acquisitionChannelModule,
        employee: employeeModule,
        employeeRole: employeeRoleModule,
        employeeWorkHour: employeeWorkHourModule,
        dailyWorkAvailability: dailyWorkAvailabilityModule,
        room: roomModule,
        equipment: equipmentModule,
        calendar: calendarModule,
        statistics: statisticsModule,
        user: userModule,
        attachmentType: attachmentTypeModule,
    }
});
