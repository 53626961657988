import httpFactory, { HttpType } from "@/api/httpFactory";
export default {
    async getAll(employeeId) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`employees/${employeeId}/work-hours`);
        response.data.forEach(workHour => {
            workHour.startTime = new Date(workHour.startTime);
            workHour.endTime = new Date(workHour.endTime);
        });
        return response.data;
    },
    async get(employeeId, workWeekId) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`employees/${employeeId}/work-weeks/${workWeekId}`);
        if (response.data.startDateTime)
            response.data.startDateTime = new Date(response.data.startDateTime);
        if (response.data.endDateTime)
            response.data.endDateTime = new Date(response.data.endDateTime);
        response.data.hours?.forEach(workHour => {
            workHour.startTime = new Date(workHour.startTime);
            workHour.endTime = new Date(workHour.endTime);
        });
        return response.data;
    },
    async create(employeeId, employeeWorkWeekRequest) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post(`employees/${employeeId}/work-weeks`, employeeWorkWeekRequest);
        if (response.data.startDateTime)
            response.data.startDateTime = new Date(response.data.startDateTime);
        if (response.data.endDateTime)
            response.data.endDateTime = new Date(response.data.endDateTime);
        response.data.hours?.forEach(workHour => {
            workHour.startTime = new Date(workHour.startTime);
            workHour.endTime = new Date(workHour.endTime);
        });
        return response.data;
    },
    async update(employeeId, workWeekId, employeeWorkWeekRequest) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post(`employees/${employeeId}/work-weeks/${workWeekId}`, employeeWorkWeekRequest);
        if (response.data.startDateTime)
            response.data.startDateTime = new Date(response.data.startDateTime);
        if (response.data.endDateTime)
            response.data.endDateTime = new Date(response.data.endDateTime);
        response.data.hours?.forEach(workHour => {
            workHour.startTime = new Date(workHour.startTime);
            workHour.endTime = new Date(workHour.endTime);
        });
        return response.data;
    }
};
