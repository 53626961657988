import { Role } from "@/config/role";
const statisticsMenu = [
    {
        key: "statistics",
        path: "",
        meta: {
            isPublic: false,
            authorize: [Role.OfficeAdministrator, Role.Administrator]
        },
        icon: "fas fa-chart-bar",
        title: "statistics.menu",
        children: [
            {
                path: "/statistics/performance",
                meta: {
                    isPublic: false,
                    authorize: [Role.OfficeAdministrator, Role.Administrator]
                },
                name: "performance",
                component: () => import(/* webpackChunkName: "statistics/performance" */ "@/views/statistics/Performance.vue"),
                key: "statistics.performance",
                title: "statistics.performance",
                icon: "fas fa-user-friends"
            },
            {
                path: "/statistics/company",
                meta: {
                    isPublic: false,
                    authorize: [Role.OfficeAdministrator, Role.Administrator]
                },
                name: "companyStatistics",
                component: () => import(/* webpackChunkName: "statistics/company" */ "@/views/statistics/Company.vue"),
                key: "statistics.company",
                title: "statistics.company",
                icon: "fas fa-building"
            },
            {
                path: "/statistics/customers",
                meta: {
                    isPublic: false,
                    authorize: [Role.OfficeAdministrator, Role.Administrator]
                },
                name: "customersStatistics",
                component: () => import(/* webpackChunkName: "statistics/customers" */ "@/views/statistics/Customers.vue"),
                key: "statistics.customers",
                title: "statistics.customers",
                icon: "fas fa-users"
            },
            {
                path: "/statistics/value-ranges",
                meta: {
                    isPublic: false,
                    authorize: [Role.OfficeAdministrator, Role.Administrator]
                },
                name: "intervalManagement",
                component: () => import(/* webpackChunkName: "statistics/value-ranges/grid" */ "@/views/statistics/intervalManagement/Index.vue"),
                key: "intervalManagement.title",
                title: "intervalManagement.title",
                icon: "fas fa-sliders-h",
                routes: [
                    {
                        path: "/statistics/value-ranges/create",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "statistics/value-ranges/create" */ "@/views/statistics/intervalManagement/CreateOrEdit.vue"),
                        key: "intervalManagement.create",
                        title: "intervalManagement.create"
                    },
                    {
                        path: "/statistics/value-ranges/edit/:id",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "statistics/value-ranges/edit" */ "@/views/statistics/intervalManagement/CreateOrEdit.vue"),
                        key: "intervalManagement.edit",
                        title: "intervalManagement.edit"
                    }
                ]
            }
        ]
    }
];
export default statisticsMenu;
