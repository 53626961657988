import equipmentApi from "@/api/equipments";
const state = {
    equipments: []
};
const actions = {
    async getAll(context, payload) {
        if (payload?.load ?? true) {
            const equipments = await equipmentApi.getAll();
            context.commit("setEquipments", equipments);
        }
        return context.getters.getEquipments;
    },
    async get(context, payload) {
        return await equipmentApi.get(payload.id);
    },
    async edit(context, payload) {
        await equipmentApi.edit(payload.id, payload.equipment);
    },
    async create(context, payload) {
        await equipmentApi.create(payload.equipment);
    },
    async isAvailable(context, payload) {
        return await equipmentApi.isAvailable(payload.id, payload.startDateTime, payload.endDateTime, payload.recurrenceRule, payload.recurrenceException, payload.appointmentId);
    }
};
const getters = {
    getEquipments(state) {
        return state.equipments;
    }
};
const mutations = {
    setEquipments(state, equipments) {
        state.equipments = equipments;
    }
};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
