import attachmentsApi from "@/api/attachments";
const state = {
    isAvailable: false,
    lastAvailabilityCheck: 0
};
const actions = {
    async isAvailable(context) {
        let isAvailable = context.state.isAvailable;
        // Check after 30 minutes when service is available and after 1 minute if not. */
        const ttl = isAvailable ? (30 * 60 * 1000) : (1 * 60 * 1000);
        if (Date.now() - context.state.lastAvailabilityCheck > ttl) {
            isAvailable = await attachmentsApi.isAvailable();
            context.commit("setAvailability", isAvailable);
        }
        return isAvailable;
    }
};
const getters = {};
const mutations = {
    setAvailability(state, isAvailable) {
        state.isAvailable = isAvailable;
        state.lastAvailabilityCheck = Date.now();
    }
};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
