import dashboardMenu from "./dashboard";
import customersMenu from "./customers";
import employeesMenu from "./employees";
import statisticsMenu from "./statistics";
import attachmentsMenu from "./attachments";
import administrationMenu from "./administration";
export default class Menu {
    static get getRoutes() {
        return Menu.buildRoutes(Menu.getTree);
    }
    static buildRoutes(menuPart) {
        let routes = [];
        menuPart.forEach(item => {
            if (item.path !== "") {
                routes.push(item);
            }
            if (item.routes) {
                routes = routes.concat(item.routes);
            }
            if (item.children) {
                routes = routes.concat(Menu.buildRoutes(item.children));
            }
        });
        return routes;
    }
    static get getTree() {
        return [
            ...dashboardMenu,
            ...customersMenu,
            ...employeesMenu,
            ...statisticsMenu,
            ...attachmentsMenu,
            ...administrationMenu
        ];
    }
}
