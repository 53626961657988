import employeeRoleApi from "@/api/employeeRoles";
const state = {};
const actions = {
    async getAll(context) {
        return await employeeRoleApi.getAll();
    },
    async get(context, payload) {
        return await employeeRoleApi.get(payload.id);
    },
    async edit(context, payload) {
        await employeeRoleApi.edit(payload.id, payload.employeeRole);
    },
    async create(context, payload) {
        await employeeRoleApi.create(payload.employeeRole);
    }
};
const getters = {};
const mutations = {};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
