import Vue from "vue";
import { ToastPlugin } from "@syncfusion/ej2-vue-notifications";
Vue.use(ToastPlugin);
export default Vue.extend({
    props: {
        position: {
            type: Object,
            required: false,
            default: function () {
                return { X: "Right", Y: "Bottom" };
            }
        }
    },
    data() {
        return {};
    },
    methods: {
        showSuccessToast(title, content) {
            this.showToast({
                title: title,
                content: content,
                cssClass: "e-toast-success"
            });
        },
        showInfoToast(title, content) {
            this.showToast({
                title: title,
                content: content,
                cssClass: "e-toast-info"
            });
        },
        showWarningToast(title, content) {
            this.showToast({
                title: title,
                content: content,
                cssClass: "e-toast-warning"
            });
        },
        showDangerToast(title, content) {
            this.showToast({
                title: title,
                content: content,
                cssClass: "e-toast-danger"
            });
        },
        showToast(options) {
            this.$refs.toast.show(options);
        },
        onClick(e) {
            e.clickToClose = true;
        }
    },
    created() {
        const toastObject = {
            showSuccessToast: this.showSuccessToast,
            showInfoToast: this.showInfoToast,
            showWarningToast: this.showWarningToast,
            showDangerToast: this.showDangerToast,
            showToast: this.showToast
        };
        Vue.prototype.$toast = toastObject;
    }
});
