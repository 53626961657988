import httpFactory, { HttpType } from "@/api/httpFactory";
export default {
    async getRecurrences(id) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`appointments/${id}/recurrences`);
        response.data.forEach(appointment => {
            appointment.StartTime = new Date(appointment.StartTime);
            appointment.EndTime = new Date(appointment.EndTime);
        });
        return response.data;
    },
    async search(query, startDate, endDate, offices, customers, services, employees) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("appointments/search", {
            params: {
                query: query,
                startDate: startDate,
                endDate: endDate,
                offices: offices,
                customers: customers,
                services: services,
                employees: employees,
                order: "asc"
            },
            paramsSerializer: {
                indexes: true
            }
        });
        response.data.forEach(appointment => {
            appointment.startTime = new Date(appointment.startTime);
            appointment.endTime = new Date(appointment.endTime);
        });
        return response.data;
    },
    async outsideWorkingHours(startDateTime, endDateTime, employeeId) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`appointments/outside-working-hours`, {
            params: {
                startDateTime: startDateTime,
                endDateTime: endDateTime,
                employeeId: employeeId
            }
        });
        response.data.forEach(appointment => {
            appointment.startTime = new Date(appointment.startTime);
            appointment.endTime = new Date(appointment.endTime);
        });
        return response.data;
    },
    async employeeOutsideWorkingHours(employeeId, officeId, dayOfWeek, startTime, endTime, startDate, endDate) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`appointments/employees/${employeeId}/outside-working-hours`, {
            params: {
                dayOfWeek: dayOfWeek,
                startTime: startTime,
                endTime: endTime,
                startDate: startDate,
                endDate: endDate
            }
        });
        response.data.forEach(appointment => {
            appointment.startTime = new Date(appointment.startTime);
            appointment.endTime = new Date(appointment.endTime);
        });
        return response.data;
    },
    async searchFirstAvailability(data) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get('appointments/first-availability', {
            params: data,
            paramsSerializer: {
                serialize: (params) => {
                    const queryStringParts = [];
                    params.hours.forEach((element, index) => {
                        queryStringParts.push(`hours[${index}].dayOfWeek=${element.dayOfWeek}`);
                        queryStringParts.push(`hours[${index}].startTime=${element.startTime.toISOString()}`);
                        queryStringParts.push(`hours[${index}].endTime=${element.endTime.toISOString()}`);
                    });
                    queryStringParts.push(`duration=${params.duration}`);
                    queryStringParts.push(`officeId=${params.officeId}`);
                    queryStringParts.push(`serviceId=${params.serviceId}`);
                    params.employeeIds?.forEach((element, index) => {
                        queryStringParts.push(`employeeIds[${index}]=${element}`);
                    });
                    params.roomIds?.forEach((element, index) => {
                        queryStringParts.push(`roomIds[${index}]=${element}`);
                    });
                    params.equipmentIds?.forEach((element, index) => {
                        queryStringParts.push(`equipmentIds[${index}]=${element}`);
                    });
                    return queryStringParts.join('&');
                }
            }
        });
        response.data.slots.forEach(appointment => {
            appointment.startDateTime = new Date(appointment.startDateTime);
            appointment.endDateTime = new Date(appointment.endDateTime);
        });
        return response.data;
    },
    async searchFirstAvailabilityByQuery(data) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(data);
        response.data.slots.forEach(appointment => {
            appointment.startDateTime = new Date(appointment.startDateTime);
            appointment.endDateTime = new Date(appointment.endDateTime);
        });
        return response.data;
    },
};
