import axios from "axios";
import { apiAxiosSettings } from "@/config/axios";
import { getAxiosApiErrors } from "@/helpers/apiHelper";
import { getCookie } from "tiny-cookie";
export var HttpType;
(function (HttpType) {
    HttpType[HttpType["Api"] = 0] = "Api";
})(HttpType || (HttpType = {}));
export default class HttpFactory {
    static getHttp(type) {
        const config = this.axiosSettings[type];
        const token = sessionStorage.getItem("token") || localStorage.getItem("token");
        if (token != null) {
            config.headers = {
                "Authorization": `Bearer ${token}`
            };
        }
        // Create and configure axios instance
        const http = axios.create(config);
        // Add XSRF token
        const xsrfToken = getCookie("XSRF-TOKEN");
        if (xsrfToken != null)
            http.defaults.headers.common['X-XSRF-TOKEN'] = xsrfToken;
        // Add response interceptor
        http.interceptors.response.use(response => response, error => Promise.reject(getAxiosApiErrors(error)));
        // Return axios instance
        return http;
    }
}
HttpFactory.axiosSettings = {
    [HttpType.Api]: apiAxiosSettings
};
