import Vue from "vue";
import "@fortawesome/fontawesome-free/css/all.css"; // Ensure you are using css-loader
import Vuetify from "vuetify/lib";
import it from "vuetify/src/locale/it";
Vue.use(Vuetify);
export default new Vuetify({
    lang: {
        locales: { it },
        current: "it"
    },
    icons: {
        iconfont: "fa"
    },
    breakpoint: {
        mobileBreakpoint: 'sm'
    },
});
