import Vue from "vue";
import AppButton from "@/components/Inputs/AppButton.vue";
export default Vue.extend({
    components: {
        "app-button": AppButton
    },
    props: {
        addSubmitButton: {
            type: Boolean,
            required: false,
            default: false
        },
        submitButtonText: {
            type: String,
            required: false,
            default: "shared.submit"
        },
        submitAction: {
            type: Function,
            required: false,
            default() {
                return () => true;
            }
        },
        addCancelButton: {
            type: Boolean,
            required: false,
            default: false
        },
        cancelButtonText: {
            type: String,
            required: false,
            default: "shared.cancel"
        },
        cancelAction: {
            type: Function,
            required: false,
            default() {
                return () => this.$router.back();
            }
        }
    }
});
