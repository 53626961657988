import httpFactory, { HttpType } from "@/api/httpFactory";
export default {
    async getAll() {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("employees");
        response.data.forEach(employee => {
            employee.employmentDate = new Date(employee.employmentDate);
            if (employee.terminationDate != null)
                employee.terminationDate = new Date(employee.terminationDate);
            employee.dailyWorkAvailabilities.forEach(dailyWorkAvailability => {
                dailyWorkAvailability.validFrom = dailyWorkAvailability.validFrom != null ? new Date(dailyWorkAvailability.validFrom) : null;
                dailyWorkAvailability.validTo = dailyWorkAvailability.validTo != null ? new Date(dailyWorkAvailability.validTo) : null;
                dailyWorkAvailability.startTime = new Date(dailyWorkAvailability.startTime);
                dailyWorkAvailability.endTime = new Date(dailyWorkAvailability.endTime);
            });
            employee.workHours.forEach(workHour => {
                workHour.validFrom = workHour.validFrom != null ? new Date(workHour.validFrom) : null;
                workHour.validTo = workHour.validTo != null ? new Date(workHour.validTo) : null;
                workHour.startTime = new Date(workHour.startTime);
                workHour.endTime = new Date(workHour.endTime);
            });
        });
        return response.data;
    },
    async get(id) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`employees/${id}`);
        return response.data;
    },
    async create(employeeRequest) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post("employees/create", employeeRequest);
        return response.data;
    },
    async edit(id, employeeRequest) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post(`employees/${id}/edit`, employeeRequest);
        return response.data;
    },
    async isAvailable(id, officeId, startDateTime, endDateTime, recurrenceRule, recurrenceException, appointmentId) {
        const http = httpFactory.getHttp(HttpType.Api);
        await http.get(`employees/${id}/available`, {
            params: {
                officeId: officeId,
                startDateTime: startDateTime,
                endDateTime: endDateTime,
                recurrenceRule: recurrenceRule,
                recurrenceException: recurrenceException,
                appointmentId: appointmentId
            }
        });
    },
    async isWorkingHours(id, officeId, startDateTime, endDateTime, recurrenceRule, recurrenceException) {
        const http = httpFactory.getHttp(HttpType.Api);
        await http.get(`employees/${id}/working-hours`, {
            params: {
                officeId: officeId,
                startDateTime: startDateTime,
                endDateTime: endDateTime,
                recurrenceRule: recurrenceRule,
                recurrenceException: recurrenceException
            }
        });
    },
    async getAvatar(id) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`employees/${id}/image`);
        return response.data;
    },
    async getContract(id) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`employees/${id}/contract`);
        return response.data;
    },
    async getAttachment(id, employeeId) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`employees/${employeeId}/attachments/${id}`);
        return response.data;
    },
    async createAttachment(employeeId, data) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post(`employees/${employeeId}/attachments`, data);
        return response;
    },
    async editAttachment(id, employeeId, data) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post(`employees/${employeeId}/attachments/${id}`, data);
        return response;
    },
    async downloadAttachment(id, employeeId) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`employees/${employeeId}/attachments/${id}/download`, {
            responseType: 'blob'
        });
        return response.data;
    }
};
