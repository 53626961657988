import customerApi from "@/api/customers";
const state = {
    customers: []
};
const actions = {
    async getAll(context, payload) {
        if (payload?.load ?? true) {
            const customers = await customerApi.getAll();
            context.commit("setCustomers", customers);
        }
        return context.getters.getCustomers;
    },
    async get(context, payload) {
        return await customerApi.get(payload.id);
    },
    async edit(context, payload) {
        return await customerApi.edit(payload.id, payload.customer);
    },
    async create(context, payload) {
        const customer = await customerApi.create(payload.customer);
        context.commit("addCustomer", customer);
        return customer;
    },
    async updateAppointmentPaymentStatus(context, payload) {
        return await customerApi.updateAppointmentPaymentStatus(payload.appointmentsPayment);
    },
    async deleteAppointmentPayment(context, payload) {
        return await customerApi.deleteAppointmentPayment(payload.id, payload.appointmentId);
    },
    async getHealthPaths(context, payload) {
        return await customerApi.getHealthPaths(payload.customerId);
    },
    async getHealthPath(context, payload) {
        return await customerApi.getHealthPath(payload.id, payload.customerId);
    },
    async createHealthPath(context, payload) {
        return await customerApi.createHealthPath(payload.customerId, payload.data);
    },
    async editHealthPath(context, payload) {
        return await customerApi.editHealthPath(payload.customerId, payload.id, payload.data);
    },
    async getAttachment(context, payload) {
        return await customerApi.getAttachment(payload.id, payload.customerId);
    },
    async createAttachment(context, payload) {
        return await customerApi.createAttachment(payload.customerId, payload.attachment);
    },
    async editAttachment(context, payload) {
        return await customerApi.editAttachment(payload.id, payload.customerId, payload.attachment);
    },
    async downloadAttachment(context, payload) {
        return await customerApi.downloadAttachment(payload.id, payload.customerId);
    }
};
const getters = {
    getCustomers(state) {
        return state.customers;
    }
};
const mutations = {
    addCustomer(state, customer) {
        if (state.customers != null)
            state.customers = [...state.customers, customer];
    },
    setCustomers(state, customers) {
        state.customers = customers;
    }
};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
