import httpFactory, { HttpType } from "@/api/httpFactory";
export default {
    async create(employeeId, dailyWorkAvailabilityRequest) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post(`employees/${employeeId}/daily-work-availabilities`, dailyWorkAvailabilityRequest);
        if (response.data.validFrom)
            response.data.validFrom = new Date(response.data.validFrom);
        if (response.data.validTo)
            response.data.validTo = new Date(response.data.validTo);
        return response.data;
    },
    async update(employeeId, id, dailyWorkAvailabilityRequest) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post(`employees/${employeeId}/daily-work-availabilities/${id}`, dailyWorkAvailabilityRequest);
        if (response.data.validFrom)
            response.data.validFrom = new Date(response.data.validFrom);
        if (response.data.validTo)
            response.data.validTo = new Date(response.data.validTo);
        return response.data;
    },
    async get(employeeId, id) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`employees/${employeeId}/daily-work-availabilities/${id}`);
        if (response.data.validFrom)
            response.data.validFrom = new Date(response.data.validFrom);
        if (response.data.validTo)
            response.data.validTo = new Date(response.data.validTo);
        return response.data;
    }
};
