import httpFactory, { HttpType } from "@/api/httpFactory";
export default {
    async getAll() {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("employee-roles");
        return response.data;
    },
    async get(id) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`employee-roles/${id}`);
        return response.data;
    },
    async create(employeeRoleRequest) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post("employee-roles/create", employeeRoleRequest);
        return response.data;
    },
    async edit(id, employeeRoleRequest) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post(`employee-roles/${id}/edit`, employeeRoleRequest);
        return response.data;
    }
};
