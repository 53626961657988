import httpFactory, { HttpType } from "@/api/httpFactory";
export default {
    async getAll() {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("customers/time-slots");
        return response.data;
    },
    async get(id) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`customers/time-slots/${id}`);
        return response.data;
    },
    async create(request) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post("customers/time-slots", request);
        return response.data;
    },
    async edit(id, request) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post(`customers/time-slots/${id}`, request);
        return response.data;
    }
};
