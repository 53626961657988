import { Role } from "@/config/role";
const attachmentsMenu = [
    {
        key: "attachments",
        path: "",
        icon: "fa fa-paperclip",
        title: "attachments.menu",
        meta: {
            isPublic: false,
            authorize: [Role.OfficeAdministrator, Role.Administrator]
        },
        children: [
            {
                path: "/attachments/types",
                name: "attachmentsTypes",
                component: () => import(/* webpackChunkName: "attachments/types/grid" */ "@/views/attachmentTypes/Index.vue"),
                key: "attachmentTypes.index",
                title: "attachmentTypes.menu",
                icon: "fa fa-paperclip",
                meta: {
                    isPublic: false,
                    authorize: [Role.OfficeAdministrator, Role.Administrator]
                },
                routes: [
                    {
                        path: "/attachments/types/create",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "attachments/types/create" */ "@/views/attachmentTypes/Create.vue")
                    },
                    {
                        path: "/attachments/types/:id/edit",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "attachments/types/edit" */ "@/views/attachmentTypes/Edit.vue")
                    }
                ]
            },
            {
                path: "/attachments/logs",
                name: "attachmentsLogs",
                component: () => import(/* webpackChunkName: "attachments/logs/grid" */ "@/views/attachments/logs/Index.vue"),
                key: "attachments.logs.index",
                title: "attachments.logs.menu",
                icon: "fas fa-align-justify",
                meta: {
                    isPublic: false,
                    authorize: [Role.OfficeAdministrator, Role.Administrator]
                }
            },
        ]
    }
];
export default attachmentsMenu;
