import acquisitionChannelApi from "@/api/acquisitionChannels";
const state = {};
const actions = {
    async getAll(context) {
        return await acquisitionChannelApi.getAll();
    },
    async get(context, payload) {
        return await acquisitionChannelApi.get(payload.id);
    },
    async edit(context, payload) {
        await acquisitionChannelApi.edit(payload.id, payload.acquisitionChannel);
    },
    async create(context, payload) {
        await acquisitionChannelApi.create(payload.acquisitionChannel);
    }
};
const getters = {};
const mutations = {};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
