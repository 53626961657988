import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Menu from "@/config/menu/menu";
import { delay } from "@/helpers/promiseHelper";
Vue.use(VueRouter);
/* eslint-disable */
const routes = [
    ...Menu.getRoutes,
    {
        path: "/account/login",
        name: "Login",
        meta: {
            isPublic: true,
            isLogin: true,
            disallowLoggedIn: true,
            layout: "LoginLayout"
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "account/login" */ "../views/account/Login.vue")
    },
    {
        path: "/account/reset-password-request",
        name: "ResetPasswordRequest",
        meta: {
            isPublic: true,
            disallowLoggedIn: true,
            layout: "LoginLayout"
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "account/resetPasswordRequest" */ "../views/account/ResetPasswordRequest.vue")
    },
    {
        path: "/account/confirm-email",
        name: "ConfirmEmail",
        meta: {
            isPublic: true,
            disallowLoggedIn: true,
            layout: "LoginLayout"
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "account/confirmEmail" */ "../views/account/ConfirmEmail.vue")
    },
    {
        path: "/account/reset-password",
        name: "ResetPassword",
        meta: {
            isPublic: true,
            disallowLoggedIn: true,
            layout: "LoginLayout"
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "account/resetPassword" */ "../views/account/ResetPassword.vue")
    },
    {
        path: "/403",
        name: "403",
        component: () => import(/* webpackChunkName: "403" */ "../views/403.vue")
    },
    {
        path: "*",
        name: "404",
        component: () => import(/* webpackChunkName: "404" */ "../views/404.vue")
    },
];
/* eslint-enable */
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});
async function getRole() {
    const isLoggedIn = store.getters["account/isLoggedIn"];
    if (!isLoggedIn)
        return undefined;
    let role = store.getters["account/getRole"];
    if (role !== '')
        return role;
    for (let iWait = 0; iWait < 50; iWait++) {
        await delay(200);
        role = store.getters["account/getRole"];
        if (role !== '')
            return role;
    }
    return '';
}
router.beforeEach(async (to, from, next) => {
    const isLoggedIn = store.getters["account/isLoggedIn"];
    // If user is logged in and tries to go to disallowed page, go to default page
    if (isLoggedIn && to.meta && to.meta.disallowLoggedIn) {
        next("/");
        return;
    }
    // Go to public endpoint
    if (to.meta && to.meta.isPublic) {
        next();
        return;
    }
    // Go to log in if guest
    if (!isLoggedIn) {
        next("/account/login");
        return;
    }
    // Redirect to 403 if you are not authorized to see the page
    const role = store.getters["account/getRole"];
    if (to.meta && to.meta.authorize && !to.meta.authorize.includes(role)) {
        next("/403");
        return;
    }
    // Go to next page
    next();
});
export default router;
