import calendarTypeApi from "@/api/calendarTypes";
const state = {};
const actions = {
    async getAll(context) {
        return await calendarTypeApi.getAll();
    },
    async get(context, payload) {
        return await calendarTypeApi.get(payload.id);
    },
    async edit(context, payload) {
        await calendarTypeApi.edit(payload.id, payload.calendarType);
    },
    async create(context, payload) {
        await calendarTypeApi.create(payload.calendarType);
    }
};
const getters = {};
const mutations = {};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
