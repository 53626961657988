import Vue from "vue";
import SpinnerMixin from "@/mixins/spinner";
export default Vue.extend({
    mixins: [SpinnerMixin],
    computed: {
        layout() {
            if (this.$route.meta && this.$route.meta.layout) {
                return this.$route.meta.layout;
            }
            return "DefaultLayout";
        }
    },
    async mounted() {
        this.createSpinner();
    }
});
