import serviceApi from "@/api/services";
const state = {
    services: null
};
const actions = {
    async getAll(context, payload) {
        let services = context.getters.getServices;
        if (payload.load || services == null) {
            services = await serviceApi.getAll();
            context.commit("setServices", services);
        }
        return services;
    },
    async get(context, payload) {
        return await serviceApi.get(payload.id);
    },
    async edit(context, payload) {
        await serviceApi.edit(payload.id, payload.service);
    },
    async create(context, payload) {
        await serviceApi.create(payload.service);
    }
};
const getters = {
    getServices(state) {
        return state.services;
    }
};
const mutations = {
    setServices(state, services) {
        state.services = services;
    }
};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
