import Vue from "vue";
import store from "@/store";
export default Vue.extend({
    props: {
        menuItem: {
            type: Object,
            required: true
        }
    },
    computed: {
        isVisible() {
            const role = store.getters["account/getRole"];
            return this.menuItem.meta === undefined ||
                this.menuItem.meta.authorize === undefined ||
                this.menuItem.meta.authorize.includes(role);
        }
    }
});
