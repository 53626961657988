import { Role } from "@/config/role";
const servicesMenu = [
    {
        key: "administration",
        path: "",
        icon: "fas fa-user-cog",
        title: "administration.menu",
        meta: {
            isPublic: false,
            authorize: [Role.OfficeAdministrator, Role.Administrator]
        },
        children: [
            {
                path: "/administration/services",
                name: "services",
                component: () => import(/* webpackChunkName: "administration/services/grid" */ "@/views/services/Index.vue"),
                key: "services.index",
                title: "services.menu",
                icon: "fas fa-briefcase",
                meta: {
                    isPublic: false,
                    authorize: [Role.OfficeAdministrator, Role.Administrator]
                },
                routes: [
                    {
                        path: "/administration/services/create",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "administration/services/create" */ "@/views/services/Create.vue")
                    },
                    {
                        path: "/administration/services/:id/edit",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "administration/services/edit" */ "@/views/services/Edit.vue")
                    }
                ]
            },
            {
                path: "/administration/equipments",
                name: "equipments",
                component: () => import(/* webpackChunkName: "administration/equipments/grid" */ "@/views/equipments/Index.vue"),
                key: "equipments.index",
                title: "equipments.menu",
                icon: "fas fa-tools",
                meta: {
                    isPublic: false,
                    authorize: [Role.OfficeAdministrator, Role.Administrator]
                },
                routes: [
                    {
                        path: "/administration/equipments/create",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "administration/equipments/create" */ "@/views/equipments/Create.vue")
                    },
                    {
                        path: "/administration/equipments/:id/edit",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "administration/equipments/edit" */ "@/views/equipments/Edit.vue")
                    }
                ]
            },
            {
                path: "/administration/rooms",
                name: "rooms",
                component: () => import(/* webpackChunkName: "administration/rooms/grid" */ "@/views/rooms/Index.vue"),
                key: "rooms.index",
                title: "rooms.menu",
                icon: "fas fa-door-open",
                meta: {
                    isPublic: false,
                    authorize: [Role.OfficeAdministrator, Role.Administrator]
                },
                routes: [
                    {
                        path: "/administration/rooms/create",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "administration/rooms/create" */ "@/views/rooms/Create.vue")
                    },
                    {
                        path: "/administration/rooms/:id/edit",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "administration/rooms/edit" */ "@/views/rooms/Edit.vue")
                    }
                ]
            },
            {
                path: "/administration/calendar-types",
                name: "calendarTypes",
                component: () => import(/* webpackChunkName: "administration/calendar-types/grid" */ "@/views/calendarTypes/Index.vue"),
                key: "calendarTypes.index",
                title: "calendarTypes.menu",
                icon: "fa fa-calendar",
                meta: {
                    isPublic: false,
                    authorize: [Role.OfficeAdministrator, Role.Administrator]
                },
                routes: [
                    {
                        path: "/administration/calendar-types/create",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "administration/calendar-types/create" */ "@/views/calendarTypes/Create.vue")
                    },
                    {
                        path: "/administration/calendar-types/:id/edit",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "administration/calendar-types/edit" */ "@/views/calendarTypes/Edit.vue")
                    }
                ]
            },
            {
                path: "/administration/offices",
                name: "offices",
                component: () => import(/* webpackChunkName: "administration/offices/grid" */ "@/views/offices/Index.vue"),
                key: "offices.index",
                title: "offices.menu",
                icon: "fas fa-building",
                meta: {
                    isPublic: false,
                    authorize: [Role.Administrator]
                },
                routes: [
                    {
                        path: "/administration/offices/create",
                        meta: {
                            isPublic: false,
                            authorize: [Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "administration/offices/create" */ "@/views/offices/Create.vue")
                    },
                    {
                        path: "/administration/offices/:id/edit",
                        meta: {
                            isPublic: false,
                            authorize: [Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "administration/offices/edit" */ "@/views/offices/Edit.vue")
                    }
                ]
            },
            {
                path: "/administration/users",
                name: "users",
                component: () => import(/* webpackChunkName: "administration/users/grid" */ "@/views/users/Index.vue"),
                key: "users.index",
                title: "users.menu",
                icon: "fa fa-user",
                meta: {
                    isPublic: false,
                    authorize: [Role.OfficeAdministrator, Role.Administrator]
                },
                routes: [
                    {
                        path: "/administration/users/create",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "administration/users/create" */ "@/views/users/Create.vue")
                    },
                    {
                        path: "/administration/users/:id/edit",
                        meta: {
                            isPublic: false,
                            authorize: [Role.OfficeAdministrator, Role.Administrator]
                        },
                        component: () => import(/* webpackChunkName: "administration/users/edit" */ "@/views/users/Edit.vue")
                    }
                ]
            }
        ]
    }
];
export default servicesMenu;
