import Vue from "vue";
import AppNavigationItem from "@/components/Navigation/AppNavigationItem.vue";
import AppNavigationGroupItem from "@/components/Navigation/AppNavigationGroupItem.vue";
export default Vue.extend({
    components: {
        AppNavigationItem,
        AppNavigationGroupItem
    },
    props: {
        menu: {
            type: Array,
            required: true
        }
    },
    computed: {
        isMenuVisible: {
            get() {
                return this.$store.getters["isMenuVisible"];
            },
            set(value) {
                this.$store.commit('showMenu', value);
            }
        }
    }
});
