export class Role {
}
Role.Employee = "Employee";
Role.EmployeeId = 1;
Role.Secretary = "Secretary";
Role.SecretaryId = 2;
Role.OfficeAdministrator = "Office administrator";
Role.OfficeAdministratorId = 3;
Role.Administrator = "Administrator";
Role.AdministratorId = 4;
