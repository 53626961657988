import currencyApi from "@/api/currencies";
const state = {};
const actions = {
    getAll(context) {
        return currencyApi.getAll();
    },
    getByCode(context, payload) {
        return currencyApi.getByCode(payload.code);
    }
};
const getters = {};
const mutations = {};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
