import Vue from "vue";
import { createSpinner, showSpinner, hideSpinner } from "@syncfusion/ej2-popups";
export default Vue.extend({
    methods: {
        createSpinner(target) {
            const spinnerTarget = target ?? this._getSpinnerTarget();
            createSpinner({
                // Specify the target for the spinner to show
                target: spinnerTarget,
            });
        },
        showSpinner(target) {
            const spinnerTarget = target ?? this._getSpinnerTarget();
            showSpinner(spinnerTarget);
        },
        hideSpinner(target) {
            const spinnerTarget = target ?? this._getSpinnerTarget();
            hideSpinner(spinnerTarget);
        },
        _getSpinnerTarget() {
            return document.getElementById("app");
        }
    }
});
