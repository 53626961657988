import httpFactory, { HttpType } from "../httpFactory";
export default {
    async getAll() {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("calendar-types");
        return response.data;
    },
    async get(id) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`calendar-types/${id}`);
        return response.data;
    },
    async create(calendarTypeRequest) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post("calendar-types/create", calendarTypeRequest);
        return response.data;
    },
    async edit(id, calendarTypeRequest) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post(`calendar-types/${id}/edit`, calendarTypeRequest);
        return response.data;
    }
};
