import httpFactory, { HttpType } from "@/api/httpFactory";
export default {
    async getRoles() {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("users/roles");
        return response.data;
    },
    async get(id) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`users/${id}`);
        return response.data;
    },
    async create(userRequest) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post("users/create", userRequest);
        return response.data;
    },
    async edit(id, userRequest) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post(`users/${id}/edit`, userRequest);
        return response.data;
    },
    async unlock(id) {
        const http = httpFactory.getHttp(HttpType.Api);
        await http.post(`users/${id}/unlock`);
    },
    async sendEmailConfirmation(id) {
        const http = httpFactory.getHttp(HttpType.Api);
        await http.post(`users/${id}/send-email-confirmation`);
    },
    async sendResetPassword(id) {
        const http = httpFactory.getHttp(HttpType.Api);
        await http.post(`users/${id}/send-reset-password`);
    }
};
