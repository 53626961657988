import Vue from "vue";
import { FormValidator } from "@syncfusion/ej2-inputs";
import AppErrorsSummary from "@/components/Form/AppErrorsSummary.vue";
import LocaleMixin from "@/mixins/locale";
import SpinnerMixin from "@/mixins/spinner";
export default Vue.extend({
    mixins: [SpinnerMixin, LocaleMixin],
    components: {
        "app-errors-summary": AppErrorsSummary
    },
    props: {
        id: {
            type: String,
            required: true
        },
        method: {
            type: String,
            required: false,
            default: "post"
        },
        submitAction: {
            type: Function,
            required: true
        },
        submitSuccessAction: {
            type: Function,
            required: false
        },
        validationOptions: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            formValidator: null,
            apiErrors: []
        };
    },
    methods: {
        async submitForm() {
            // Reset errors
            this.apiErrors = [];
            // Validate form
            if (this.formValidator != null && !this.formValidator.validate())
                return false;
            this.showSpinner();
            try {
                const result = await this.submitAction();
                if (result === false)
                    return false;
                if (this.submitSuccessAction !== undefined)
                    await this.submitSuccessAction();
                return true;
            }
            catch (errors) {
                this.apiErrors = errors.map((errorObject) => errorObject.message);
                return false;
            }
            finally {
                this.hideSpinner();
            }
        },
        addRules(name, rules) {
            if (this.formValidator != null)
                this.formValidator.addRules(name, rules);
        },
        removeRules(name, rules) {
            if (this.formValidator != null)
                this.formValidator.removeRules(name, rules);
        },
        reset() {
            if (this.formValidator != null)
                this.formValidator.reset();
        }
    },
    mounted() {
        if (this.validationOptions != undefined) {
            this.validationOptions.locale = this.appLocale;
            this.formValidator = new FormValidator("#" + this.id, this.validationOptions);
        }
    }
});
