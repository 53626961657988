import httpFactory, { HttpType } from "@/api/httpFactory";
export default {
    async getAll() {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("rooms");
        return response.data;
    },
    async get(id) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`rooms/${id}`);
        return response.data;
    },
    async create(roomRequest) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post("rooms/create", roomRequest);
        return response.data;
    },
    async edit(id, roomRequest) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post(`rooms/${id}/edit`, roomRequest);
        return response.data;
    },
    async isAvailable(id, startDateTime, endDateTime, customersNumber, recurrenceRule, recurrenceException, appointmentId) {
        const http = httpFactory.getHttp(HttpType.Api);
        await http.get(`rooms/${id}/available`, {
            params: {
                startDateTime: startDateTime,
                endDateTime: endDateTime,
                recurrenceRule: recurrenceRule,
                recurrenceException: recurrenceException,
                customersNumber: customersNumber,
                appointmentId: appointmentId
            }
        });
    }
};
