import Vue from "vue";
import { CheckBoxPlugin } from "@syncfusion/ej2-vue-buttons";
Vue.use(CheckBoxPlugin);
export default Vue.extend({
    model: {
        prop: "checked",
        event: "change"
    },
    props: {
        id: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: false,
            default: ""
        },
        checked: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            dataMsgContainerId: `${this.id}-error`
        };
    },
    methods: {
        emitValue(value) {
            this.$emit("change", value);
        }
    }
});
