import Vue from "vue";
import { SwitchPlugin } from "@syncfusion/ej2-vue-buttons";
Vue.use(SwitchPlugin);
export default Vue.extend({
    model: {
        prop: "checked",
        event: "changed"
    },
    props: {
        id: {
            type: String,
            required: true
        },
        enabled: {
            type: Boolean,
            required: false,
            default: true
        },
        checked: {
            type: [Boolean],
            required: false,
            default: false
        },
        cssClass: {
            type: String,
            required: false,
            default: ""
        },
        value: {
            type: [String],
            required: false,
            default: ""
        }
    },
    methods: {
        emitValue(value) {
            this.$emit("changed", value);
        }
    }
});
