import httpFactory, { HttpType } from "@/api/httpFactory";
export default {
    async getAll() {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("customers");
        return response.data;
    },
    async get(id) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`customers/${id}`);
        return response.data;
    },
    async create(customerRequest) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post("customers/create", customerRequest);
        return response.data;
    },
    async edit(id, customerRequest) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post(`customers/${id}/edit`, customerRequest);
        return response.data;
    },
    async updateAppointmentPaymentStatus(request) {
        const http = httpFactory.getHttp(HttpType.Api);
        await http.post(`customers/appointments/payments`, request);
    },
    async deleteAppointmentPayment(id, appointmentId) {
        const http = httpFactory.getHttp(HttpType.Api);
        await http.delete(`customers/${id}/appointments/${appointmentId}/payments`);
    },
    async getHealthPaths(customerId) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`customers/${customerId}/health-paths`);
        return response.data;
    },
    async getHealthPath(id, customerId) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`customers/${customerId}/health-paths/${id}`);
        return response.data;
    },
    async createHealthPath(customerId, data) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post(`customers/${customerId}/health-paths`, data);
        return response;
    },
    async editHealthPath(customerId, id, data) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post(`customers/${customerId}/health-paths/${id}`, data);
        return response;
    },
    async getAttachment(id, customerId) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`customers/${customerId}/attachments/${id}`);
        return response.data;
    },
    async createAttachment(customerId, data) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post(`customers/${customerId}/attachments`, data);
        return response;
    },
    async editAttachment(id, customerId, data) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post(`customers/${customerId}/attachments/${id}`, data);
        return response;
    },
    async downloadAttachment(id, customerId) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`customers/${customerId}/attachments/${id}/download`, {
            responseType: 'blob'
        });
        return response.data;
    }
};
