function getProblemDetailsErrors(problemDetails) {
    if (problemDetails.errors) {
        const errors = [];
        for (const key in problemDetails.errors) {
            problemDetails.errors[key].forEach((message) => {
                errors.push({ message: message, field: key == "" ? undefined : key });
            });
        }
        return errors;
    }
    else if (problemDetails.detail && problemDetails.detail !== "")
        return [{ message: problemDetails.detail }];
    else if (problemDetails.title && problemDetails.title !== "")
        return [{ message: problemDetails.title }];
    return null;
}
export function getApiErrors(httpRequest) {
    try {
        const json = JSON.parse(httpRequest.responseText);
        const errors = getProblemDetailsErrors(json);
        if (errors != null)
            return errors;
    }
    catch {
        // Empty
    }
    return [{ message: httpRequest.responseText }];
}
export function getAxiosApiErrors(error) {
    if (error.response && error.response.data) {
        const errors = getProblemDetailsErrors(error.response.data);
        if (errors != null)
            return errors;
    }
    return [{ message: error.message }];
}
