import httpFactory, { HttpType } from "@/api/httpFactory";
export default {
    async getAll() {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("equipments");
        return response.data;
    },
    async get(id) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`equipments/${id}`);
        return response.data;
    },
    async create(equipmentRequest) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post("equipments/create", equipmentRequest);
        return response.data;
    },
    async edit(id, equipmentRequest) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post(`equipments/${id}/edit`, equipmentRequest);
        return response.data;
    },
    async isAvailable(id, startDateTime, endDateTime, recurrenceRule, recurrenceException, appointmentId) {
        const http = httpFactory.getHttp(HttpType.Api);
        await http.get(`equipments/${id}/available`, {
            params: {
                startDateTime: startDateTime,
                endDateTime: endDateTime,
                recurrenceRule: recurrenceRule,
                recurrenceException: recurrenceException,
                appointmentId: appointmentId
            }
        });
    }
};
