import officeApi from "@/api/offices";
const state = {
    offices: []
};
const actions = {
    async getAll(context, payload) {
        if (payload?.load ?? true) {
            const offices = await officeApi.getAll();
            context.commit("setOffices", offices);
        }
        return context.getters.getOffices;
    },
    async get(context, payload) {
        return await officeApi.get(payload.id);
    },
    async edit(context, payload) {
        await officeApi.edit(payload.id, payload.office);
    },
    async create(context, payload) {
        await officeApi.create(payload.office);
    }
};
const getters = {
    getOffices(state) {
        return state.offices;
    }
};
const mutations = {
    setOffices(state, offices) {
        state.offices = offices;
    }
};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
