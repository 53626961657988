import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@fortawesome/fontawesome-free/css/all.css";
import i18n from "@/plugins/i18n";
import "@/components/load";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import LoginLayout from "@/layouts/LoginLayout.vue";
import itLocale from "@/locales/syncfusion/it.json";
import { registerLicense, L10n, loadCldr, setCulture, setCurrencyCode, enableRipple } from "@syncfusion/ej2-base";
import numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import currencies from "cldr-data/main/it/currencies.json";
import gregorian from "cldr-data/main/it/ca-gregorian.json";
import numbers from "cldr-data/main/it/numbers.json";
import timeZoneNames from "cldr-data/main/it/timeZoneNames.json";
import weekData from "cldr-data/supplemental/weekData.json";
import moment from "moment/moment";
registerLicense(process.env.VUE_APP_SYNCFUSION_LICENSE_KEY);
Vue.config.productionTip = false;
Vue.component("DefaultLayout", DefaultLayout);
Vue.component("LoginLayout", LoginLayout);
L10n.load({
    it: itLocale
});
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData, currencies);
setCulture(process.env.VUE_APP_I18N_LOCALE || "en");
setCurrencyCode("EUR");
moment.locale(process.env.VUE_APP_I18N_LOCALE || "en");
enableRipple(true);
const run = async function () {
    try {
        await store.dispatch("account/refreshToken");
        await store.dispatch("account/scheduleRefreshTokens");
        await store.dispatch("account/getAccountInfo");
        if (store.getters["account/hasMultipleOffices"] === false) {
            const officeId = store.getters["account/getSingleOfficeId"];
            const office = await store.dispatch("office/get", {
                id: officeId
            });
            if (office.currencyCode != null)
                setCurrencyCode(office.currencyCode);
        }
    }
    catch (e) {
        // Empty
    }
    const vue = new Vue({
        router,
        store,
        vuetify,
        i18n,
        render: h => h(App)
    });
    vue.$mount("#app");
};
run();
