import statisticsApi from "@/api/statistics";
const state = {};
const actions = {
    async services(context, payload) {
        return statisticsApi.getServiceStatistics(payload.startDate, payload.endDate, payload.employeeId, payload.officeId, payload.granularity);
    },
    async servicesRevenue(context, payload) {
        return statisticsApi.getServiceRevenueStatistics(payload.startDate, payload.endDate, payload.employeeId, payload.officeId, payload.granularity);
    },
    async servicesProfit(context, payload) {
        return statisticsApi.getServiceProfitStatistics(payload.startDate, payload.endDate, payload.employeeId, payload.officeId, payload.granularity);
    },
    async servicesCostOverRevenue(context, payload) {
        return statisticsApi.getServiceCostOverRevenueStatistics(payload.startDate, payload.endDate, payload.employeeId, payload.officeId, payload.granularity);
    },
    async servicesEmployeeCost(context, payload) {
        return statisticsApi.getServiceEmployeeCostStatistics(payload.startDate, payload.endDate, payload.employeeId, payload.officeId, payload.granularity);
    },
    async servicesTrend(context, payload) {
        return statisticsApi.getServicesTrendStatistics(payload.startDate, payload.endDate, payload.employeeId, payload.officeId, payload.granularity);
    },
    async workedHours(context, payload) {
        return statisticsApi.getWorkedHoursStatistics(payload.startDate, payload.endDate, payload.employeeId, payload.officeId, payload.granularity);
    },
    async customersRetention(context, payload) {
        return statisticsApi.getCustomersRetention(payload.startDate, payload.endDate, payload.employeeId, payload.officeId, payload.granularity);
    },
    async customersAcquisitionChannels(context, payload) {
        return statisticsApi.getCustomersAcquisitionChannels(payload.startDate, payload.endDate, payload.granularity);
    },
    async customersTimeSlots(context, payload) {
        return statisticsApi.getCustomersTimeSlots(payload.startDate, payload.endDate, payload.granularity);
    },
    async healthPathsGymConversion(context, payload) {
        return statisticsApi.getHealthPathsGymConversion(payload.startDate, payload.endDate, payload.employeeId, payload.officeId, payload.granularity);
    },
    async healthPathsDuration(context, payload) {
        return statisticsApi.getHealthPathsDuration(payload.startDate, payload.endDate, payload.employeeId, payload.officeId, payload.granularity);
    },
    async healthPathsDurationStandardDeviation(context, payload) {
        return statisticsApi.getHealthPathsDurationStandardDeviation(payload.startDate, payload.endDate, payload.employeeId, payload.officeId, payload.granularity);
    },
    async appointmentsTrials(context, payload) {
        return statisticsApi.getAppointmentsTrialsStatistics(payload.startDate, payload.endDate, payload.employeeId, payload.officeId, payload.granularity);
    },
    async appointmentsTrialsOverNoNewAppointments(context, payload) {
        return statisticsApi.getAppointmentsTrialsOverNoNewAppointmentsStatistics(payload.startDate, payload.endDate, payload.employeeId, payload.officeId, payload.granularity);
    },
    async valueRangesById(context, payload) {
        return statisticsApi.getValueRangesById(payload.id);
    },
    async rangesTypes(context) {
        return statisticsApi.getRangesTypes();
    },
    async newValueRanges(context, payload) {
        return statisticsApi.createValueRanges(payload.type, payload.values);
    },
    async editValueRanges(context, payload) {
        return statisticsApi.updateValueRanges(payload.id, payload.type, payload.values);
    }
};
const getters = {};
const mutations = {};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
