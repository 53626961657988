import Vue from "vue";
import { TextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
Vue.use(TextBoxPlugin);
export default Vue.extend({
    props: {
        id: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            required: false,
            default: ""
        },
        type: {
            type: String,
            required: false,
            default: "text"
        },
        enabled: {
            type: Boolean,
            required: false,
            default: true
        },
        value: {
            type: String,
            required: false
        },
        cssClass: {
            type: String,
            required: false,
            default: ""
        }
    },
    data() {
        return {
            dataMsgContainerId: `${this.id}-error`
        };
    },
    methods: {
        emitValue(value) {
            this.$emit("input", value);
        },
        setValue(value) {
            this.value = value;
        }
    }
});
