import Vue from "vue";
import { mapGetters } from "vuex";
export default Vue.extend({
    name: "Toolbar",
    components: {},
    methods: {
        logout() {
            this.$store.dispatch("account/logout").then(() => {
                this.$router.push("/account/login");
            });
        },
        changePassword() {
            this.$root.$emit("openChangePasswordModal");
        },
        showMenu() {
            this.$store.commit('showMenu', true);
        }
    },
    computed: {
        ...mapGetters({ name: "account/getFullName" })
    }
});
