import { Role } from "@/config/role";
const dashboardMenu = [
    {
        key: "dashboard",
        path: "/",
        name: "dashboard",
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
        icon: "fa-tachometer-alt",
        title: "dashboard.menu"
    },
    {
        key: "calendar",
        path: "/calendar",
        name: "calendar",
        meta: {
            isPublic: false,
            authorize: [Role.Secretary, Role.OfficeAdministrator, Role.Administrator, Role.Employee]
        },
        component: () => import(/* webpackChunkName: "calendar/calendar" */ "@/views/calendar/Index.vue"),
        icon: "far fa-calendar",
        title: "calendar.menu"
    },
    {
        key: "payments",
        path: "/payments",
        name: "payments",
        meta: {
            isPublic: false,
            authorize: [Role.Secretary, Role.OfficeAdministrator, Role.Administrator]
        },
        component: () => import(/* webpackChunkName: "payments/payments" */ "@/views/payments/Index.vue"),
        icon: "far fa-credit-card",
        title: "payments.menu"
    },
    {
        key: "employeeWorkWeeks",
        path: "/work-weeks",
        name: "employeeWorkWeeks",
        meta: {
            isPublic: false,
            authorize: [Role.Employee]
        },
        component: () => import(/* webpackChunkName: "employees/work-weeks/grid" */ "@/views/employees/workWeeks/Index.vue"),
        icon: "fas fa-business-time",
        title: "employeeWorkHours.menu",
        routes: [
            {
                path: "/work-weeks/create",
                meta: {
                    isPublic: false,
                    authorize: [Role.Employee]
                },
                component: () => import(/* webpackChunkName: "employees/work-hours/create" */ "@/views/employees/WorkHoursCreate.vue")
            },
            {
                path: "/work-weeks/:workWeekId/edit",
                meta: {
                    isPublic: false,
                    authorize: [Role.Employee]
                },
                component: () => import(/* webpackChunkName: "employees/work-hours/edit" */ "@/views/employees/WorkHoursEdit.vue")
            }
        ]
    },
    {
        key: "employeeDailyWorkAvailabilities",
        path: "/daily-work-availabilities",
        name: "employeeDailyWorkAvailabilities",
        meta: {
            isPublic: false,
            authorize: [Role.Employee]
        },
        component: () => import(/* webpackChunkName: "employees/daily-work-availabilities/grid" */ "@/views/dailyWorkAvailability/Index.vue"),
        //icon: "fas fa-business-time",
        //icon: "fas fa-mug-hot",
        icon: "fas fa-sun",
        title: "dailyWorkAvailability.menu",
        routes: [
            {
                path: "/daily-work-availabilities/create",
                meta: {
                    isPublic: false,
                    authorize: [Role.Employee]
                },
                component: () => import(/* webpackChunkName: "employees/daily-work-availabilities/create" */ "@/views/dailyWorkAvailability/CreateOrEdit.vue")
            },
            {
                path: "/daily-work-availabilities/edit/:id",
                meta: {
                    isPublic: false,
                    authorize: [Role.Employee]
                },
                component: () => import(/* webpackChunkName: "employees/daily-work-availabilities/edit" */ "@/views/dailyWorkAvailability/CreateOrEdit.vue")
            },
        ]
    }
];
export default dashboardMenu;
