import httpFactory, { HttpType } from "../httpFactory";
export default {
    async getAll(ownerType) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("attachment-types", {
            params: {
                ownerType: ownerType
            }
        });
        return response.data;
    },
    async get(id) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`attachment-types/${id}`);
        return response.data;
    },
    async create(attachmentTypeRequest) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post("attachment-types", attachmentTypeRequest);
        return response.data;
    },
    async edit(id, attachmentTypeRequest) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post(`attachment-types/${id}`, attachmentTypeRequest);
        return response.data;
    }
};
