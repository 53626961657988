import Vue from "vue";
import { TimePickerPlugin } from "@syncfusion/ej2-vue-calendars";
Vue.use(TimePickerPlugin);
export default Vue.extend({
    props: {
        id: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: false,
            default: function () {
                return this.id;
            }
        },
        enabled: {
            type: Boolean,
            required: false,
            default: true
        },
        placeholder: {
            type: String,
            required: false,
            default: ""
        },
        value: {
            type: [Date, String],
            required: false
        },
        openOnFocus: {
            type: Boolean,
            required: false,
            default: false
        },
        cssClass: {
            type: String,
            required: false,
            default: ""
        },
        step: {
            type: Number,
            required: false,
            default: 30
        }
    },
    data() {
        return {
            dataMsgContainerId: `${this.id}-error`
        };
    },
    methods: {
        emitValue(value) {
            this.$emit("input", value);
        },
        onFocus() {
            if (this.openOnFocus)
                this.$refs[this.id].show();
        }
    }
});
