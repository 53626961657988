import usersApi from "@/api/users";
const state = {};
const actions = {
    async getRoles(context) {
        return await usersApi.getRoles();
    },
    async get(context, payload) {
        return await usersApi.get(payload.id);
    },
    async edit(context, payload) {
        await usersApi.edit(payload.id, payload.user);
    },
    async create(context, payload) {
        await usersApi.create(payload.user);
    },
    async unlock(context, payload) {
        await usersApi.unlock(payload.id);
    },
    async sendEmailConfirmation(context, payload) {
        await usersApi.sendEmailConfirmation(payload.id);
    },
    async sendResetPassword(context, payload) {
        await usersApi.sendResetPassword(payload.id);
    }
};
const getters = {};
const mutations = {};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
