import httpFactory, { HttpType } from "@/api/httpFactory";
export default {
    async getAll() {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get("services");
        return response.data;
    },
    async get(id) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.get(`services/${id}`);
        return response.data;
    },
    async create(serviceRequest) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post("services/create", serviceRequest);
        return response.data;
    },
    async edit(id, serviceRequest) {
        const http = httpFactory.getHttp(HttpType.Api);
        const response = await http.post(`services/${id}/edit`, serviceRequest);
        return response.data;
    }
};
